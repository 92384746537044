import React from "react";
import { Container } from "components/Container/Container";

import { useWindowWidth } from "lib/hooks/useWindowWidth";
import { ReactComponent as Link } from "../../assets/icons/contact.svg";
import { useQuery } from "react-query";
import { getAboutUsPage } from "api/General";
import { Triangle } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import "./AboutUs.scss";

export const AboutUs = () => {
  const windowWidth = useWindowWidth();
  const isTablet = windowWidth <= 1024;
  const {
    i18n: { language },
  } = useTranslation();

  const isEnglish = language === "en";
  const { data, isLoading, isError } = useQuery(
    "getAboutUsPage",
    getAboutUsPage
  );

  const aboutUsData = data?.data ?? {};

  if (isLoading) {
    return (
      <div className="AboutUs">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#000"
              ariaLabel="triangle-loading"
              visible
            />
          </div>
        </Container>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="AboutUs">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              {isEnglish
                ? "Something went wrong, please try again!"
                : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
            </p>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <>
      <div className="AboutUs">
        <div className="AboutUs__top">
          <Container>
            <div className="AboutUs__top__wrapper">
              <h1>{aboutUsData?.profile_title}</h1>
              <p>{aboutUsData?.profile_description}</p>
              <div className="AboutUs__top__wrapper__bottom">
                <div className="AboutUs__top__wrapper__bottom--img">
                  <img src={aboutUsData?.profile_image} alt="img" />
                </div>
                <div className="AboutUs__top__wrapper__bottom--items">
                  {aboutUsData?.info?.map(
                    (
                      item: {
                        title: string;
                        icon: string;
                        description: string;
                        info: string;
                      },
                      index: number
                    ) => {
                      return (
                        <div
                          key={`${item.title}-${index}`}
                          className="AboutUs__top__wrapper__bottom--items-item"
                        >
                          <div className="AboutUs__top__wrapper__bottom--items-item-icon">
                            <img
                              style={{
                                width: 23,
                                height: 23,
                                objectFit: "contain",
                              }}
                              src={item.icon}
                              alt="img"
                            />
                          </div>
                          <span>{item?.title}</span>
                          <p>{item?.description}</p>
                          <label>{item?.info}</label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="AboutUs__bottom">
          <Container>
            <div className="AboutUs__bottom__wrapper">
              <div className="AboutUs__bottom__wrapper--desc">
                <h1>{aboutUsData?.posts?.title}</h1>
                <p>{aboutUsData?.posts?.description}</p>
              </div>
              {aboutUsData?.posts?.posts?.map(
                (
                  item: {
                    title: string;
                    description: string;
                    image: string;
                  },
                  index: number
                ) => {
                  return index % 2 === 0 || isTablet ? (
                    <div
                      key={`${item.title}-${index}`}
                      className="AboutUs__bottom__wrapper--top"
                    >
                      <div className="AboutUs__bottom__wrapper--top-left">
                        <div className="AboutUs__bottom__wrapper--top-left-link">
                          <Link />
                        </div>
                        <h1>{item?.title}</h1>
                        <p>{item?.description}</p>
                      </div>
                      <div className="AboutUs__bottom__wrapper--top-right">
                        <img alt="img" src={item?.image} />
                      </div>
                    </div>
                  ) : (
                    <div
                      key={`${item.title}-${index}`}
                      className="AboutUs__bottom__wrapper--top"
                    >
                      <div className="AboutUs__bottom__wrapper--top-right">
                        <img alt="img" src={item?.image} />
                      </div>
                      <div className="AboutUs__bottom__wrapper--top-left">
                        <div className="AboutUs__bottom__wrapper--top-left-link">
                          <Link />
                        </div>
                        <h1>{item?.title}</h1>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </Container>
        </div>
      </div>
      <div className="Footer__main__top">
        <Container>
          <div className="Footer__main__top__wrapper">
            <h1>{aboutUsData?.more_info?.title}</h1>
            <p>{aboutUsData?.more_info?.description}</p>
            <div className="Footer__main__top__wrapper_btns">
              <div className="Header--bottom--buttons">
                <button
                  onClick={() =>
                    (window.location.href =
                      aboutUsData?.more_info?.first_button_url)
                  }
                  className="Header--bottom--buttons"
                >
                  {aboutUsData?.more_info?.first_button_text}
                </button>
                <button
                  onClick={() =>
                    (window.location.href =
                      aboutUsData?.more_info?.second_button_url)
                  }
                  className="Header--bottom--buttons-rev"
                >
                  {aboutUsData?.more_info?.second_button_text}
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
