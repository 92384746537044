import cs from "classnames";
import { useRouter } from "lib/hooks/useRouter";
import "./MainTemplate.scss";

interface Props {
  children: React.ReactNode;
}

export const MainTemplate = ({ children }: Props) => {
  const history = useRouter();

  return (
    <div
      className={cs(
        "MainTemplate",
        history.location.pathname !== "/" && "MainTemplate__white",
        history.location.pathname === "/" && "MainTemplate__home"
      )}
    >
      {children}
    </div>
  );
};
