import cs from "classnames";
import { useRouter } from "lib/hooks/useRouter";
import { ReactComponent as Logoo } from "../../assets/images/logo.svg";

import "./Logo.scss";

interface Props {
  invert?: boolean;
}

export const Logo = (props: Props) => {
  const router = useRouter();

  return (
    <div
      className={cs("Logo", { Logo__invert: props.invert })}
      onClick={() => {
        router.history.push("/");
      }}
    >
      <Logoo />
    </div>
  );
};
