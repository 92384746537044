import { apiRequest } from "./Api";

export interface ContactUs {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  message: string;
}

export interface SubscribeField {
  email: string;
}


export const getTranslations = async () =>
    apiRequest<undefined, any>("get", "labels");


export const getCategoryPage = async () =>
  apiRequest<undefined, any>("get", "page/category");

export const getAllCategories = async () =>
  apiRequest<undefined, any>("get", "categories/all");

export const getSingleCategory = async (slug: string) =>
  apiRequest<undefined, any>("get", `categories/${slug}`);

export const getHomePage = async () =>
  apiRequest<undefined, any>("get", "page/home");


export const getAboutUsPage = async () =>
  apiRequest<undefined, any>("get", "page/about-us");

export const getContactUsPage = async () =>
  apiRequest<undefined, any>("get", "page/contact");

export const setContactUs = async (data: ContactUs) =>
  apiRequest<ContactUs, any>("post", "page/contactUs", data);

export const getPortfolioItems = async (category?: string) =>
  apiRequest<undefined, any>("get", `portfolio/all?category=${category}`);

export const getPortfolioPage = async () =>
  apiRequest<undefined, any>("get", "page/portfolio/");

export const getPortfolioCategories = async () =>
  apiRequest<undefined, any>("get", "portfolio/categories/all");

export const getPortfolioBySlug = async (slug?: string) =>
  apiRequest<undefined, any>("get", `portfolio/${slug}`);

export const subscribeUser = async (data: SubscribeField) =>
  apiRequest<SubscribeField, any>("post", "subscribe", data);
