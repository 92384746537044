import Axios from "axios";
import i18n from "i18next";

Axios.interceptors.request.use(
  (config) => {
    if (config.url?.includes("labels") && i18n.language === "en") {
      return config;
    }

    if (config.url?.includes("?")) {
      config.url = `${config.url}&lang=${i18n.language}`;
      return config;
    }

    config.url = `${config.url}?lang=${i18n.language}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function apiRequest<D = {}, R = unknown>(
  method: "get" | "delete" | "head" | "options" | "post" | "put" | "patch",
  path: string,
  input?: D,
  options?: any
) {
  const res = await Axios.request<R>({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: method,
    data: input,
    headers: options,
  });
  return res.data;
}

export * from "./User";
