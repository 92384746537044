// @ts-nocheck

import React from "react";
import { ThemeLink } from "components/ThemeLink/ThemeLink";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useContactUs } from "lib/hooks/mutations/useContact";
import { useTranslation } from "react-i18next";

export const ContactForm = () => {
  const contactUs = useContactUs();
  const {
    i18n: { language },
  } = useTranslation("labels");

  const isEnglish = language === "en";

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      message: "",
      termsAndConditions: true,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .min(4)
        .required(
          isEnglish ? "First name is required!" : "Emri është i nevojshëm!"
        ),
      last_name: Yup.string()
        .min(4)
        .required(
          isEnglish ? "Last name is required!" : "Mbiemri është i nevojshëm"
        ),
      phone: Yup.number()
        .typeError(
          isEnglish
            ? "Phone number should contains only numbers!"
            : "Numri i telefonit duhet te permbajë numra!"
        )
        .required(
          isEnglish
            ? "Phone number is required!"
            : "Numri i telefonit është i nevojshëm!"
        ),
      email: Yup.string()
        .email()
        .required(
          isEnglish ? "Email is required!" : "Ju lutemi shënoni emailin tuaj!"
        ),
      message: Yup.string().required(
        isEnglish ? "Message is required!" : "Ju lutemi shkruani mesazhin tuaj!"
      ),
      termsAndConditions: Yup.boolean().oneOf(
        [true],
        isEnglish
          ? "Please confirm that you agree to the terms and conditions!"
          : "Ju lutemi konfirmoni se a pajtoheni me termet dhe kushtet!"
      ),
    }),
    onSubmit: async (values, formikHelpers) => {
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        message: values.message,
        phone: values.phone,
        email: values.email,
      };
      contactUs.mutate(data, {
        onSuccess: () => {
          formik.resetForm();
        },
      });
    },
  });

  // @ts-ignore
  return (
    <div className="ContactForm">
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12" sm="6">
            <FormGroup className="form-group">
              <Label for="first_name">
                {isEnglish ? "First name" : "Emri"}
              </Label>
              <Input
                id="first_name"
                type="text"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                className="form-control"
                invalid={
                  !!formik.errors.first_name && formik.touched.first_name
                }
              />
              <FormFeedback>{formik.errors.first_name}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup className="form-group">
              <Label for="last_name">
                {isEnglish ? "Last name" : "Mbiemri"}
              </Label>
              <Input
                id="last_name"
                type="text"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                classlastname="form-control"
                invalid={!!formik.errors.last_name && formik.touched.last_name}
              />
              <FormFeedback>{formik.errors.last_name}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm="12">
            <FormGroup>
              <Label for="email"> {isEnglish ? "Email" : "Email"}</Label>
              <Input
                id="email"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="form-control-custom"
                invalid={!!formik.errors.email && formik.touched.email}
              />
              <FormFeedback>{formik.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm="12">
            <FormGroup>
              <Label for="phone">
                {isEnglish ? "Phone number" : "Numri i telefonit"}
              </Label>
              <Input
                id="phone"
                type="tel"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                className="form-control-custom"
                invalid={!!formik.errors.phone && formik.touched.phone}
              />
              <FormFeedback>{formik.errors.phone}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label for="message">{isEnglish ? "Message" : "Mesazhi"}</Label>
              <Input
                id="message"
                type="textarea"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                className="form-control-custom"
                invalid={!!formik.errors.message && formik.touched.message}
              />
              <FormFeedback>{formik.errors.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup
              style={{ marginTop: 24 }}
              check
              className="agreement mt-4 CheckoutFooter"
            >
              <Label
                for="termsAndConditions"
                check={formik.values.termsAndConditions}
              >
                <Input
                  type="checkbox"
                  name="termsAndConditions"
                  id="termsAndConditions"
                  onChange={formik.handleChange}
                  invalid={
                    !!formik.errors.termsAndConditions &&
                    formik.touched.termsAndConditions
                  }
                />
                {isEnglish ? "You agree to our friendly" : "Unë pranoj"}{" "}
                <ThemeLink to="/page/36869">
                  {isEnglish ? "privacy policy" : "politikën e privatësisë"}.
                </ThemeLink>
                <FormFeedback>{formik.errors.termsAndConditions}</FormFeedback>
              </Label>
            </FormGroup>
          </Col>
          <Col md="12" lg="12" xs="12">
            <FormGroup>
              <div className="Header--bottom--buttons">
                <button
                  type="submit"
                  disabled={contactUs.isLoading}
                  className=" Contact__btn_submit w-100-mobile"
                >
                  {isEnglish ? "Send" : "Dërgo"}
                </button>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
