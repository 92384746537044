import { useMutation, UseMutationOptions } from "react-query";
import { toast } from "react-toastify";
import { ContactUs, setContactUs } from "api/General";
import { useTranslation } from "react-i18next";

export const useContactUs = (
  options?: UseMutationOptions<any, any, ContactUs, unknown>
) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  return useMutation(setContactUs, {
    onSuccess: () => {
      toast.success(
        isEnglish
          ? "Thank you for contacting us, we will be in touch soon!"
          : "Faleminderit çë na kontaktuat, së shpejti do t'iu kontaktojmë!"
      );
    },
    onError: () => {
      toast.error(
        isEnglish
          ? "Something went wrong, please try again!"
          : "Dicka shkoi gabim, ju lutemi provoni përsëri!"
      );
    },
    ...options,
  });
};
