// @ts-nocheck
import React, { useState } from "react";
import cs from "classnames";
import { Col, Row } from "reactstrap";
import { Container } from "../../components/Container/Container";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-bisht.svg";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import { Select } from "components/SortSelect/Select";
import { useQuery } from "react-query";
import {
  getPortfolioCategories,
  getPortfolioItems,
  getPortfolioPage,
} from "api/General";
import { Triangle } from "react-loader-spinner";

import "./HowTo.scss";
import { useTranslation } from "react-i18next";
import { removeHTMLEntities } from "lib/helpers/removeHTMLEntities";

export interface CoursesResponse {
  id: number;
  databaseId: number;
  content: string;
  featuredImage: {
    node: {
      id: string;
      mediaItemUrl: string;
      slug: string;
    };
  };
  slug: string;
  title: string;

  document: {
    link: string;
    id: string;
  };
  productBrands: {
    nodes: {
      name: string;
      id: string;
    };
  };
  courseVideo: {
    video: string;
  };
}

export interface CoursesCategories {
  courseCategories: {
    nodes: CoursesCategory[];
  };
}

interface CoursesCategory {
  id: number;
  title: string;
  slug: string;
  content: string;
  short_description: string;
}

interface Props {}

export const HowTo = (props: RouteComponentProps<Props>) => {
  const history = useHistory();
  const [active, setActive] = useState("");
  const windowWidth = useWindowWidth();

  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const {
    data: portfolioPageData,
    isLoading: portfolioPageLoading,
    isError: portfolioPageError,
  } = useQuery("getPortfolioPage", getPortfolioPage);

  const {
    data: portfolioItemsData,
    isLoading: portfolioItemsLoading,
    isError: portfolioItemsError,
  } = useQuery(["getPortfolioItems", active], () => getPortfolioItems(active));

  const {
    data: courseCategories,
    isLoading: courseCategoriesLoading,
    isError: courseCategoriesError,
  } = useQuery("getPortfolioCategories", getPortfolioCategories);

  const isMobile = windowWidth <= 1024;

  const courses = isMobile
    ? portfolioItemsData ?? []
    : portfolioItemsData?.slice(1) ?? [];

  const categoriesOptions = courseCategories?.map((category: any) => ({
    value: category.slug,
    label: category.title,
  }));

  const courseOne = portfolioItemsData?.[0];

  const changeCategory = (slug: string) => {
    setActive(slug);
  };

  const changeCategoryMobile = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActive(e.target.value);
  };

  if (
    portfolioItemsLoading ||
    courseCategoriesLoading ||
    portfolioPageLoading
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
          marginBottom: 100,
        }}
      >
        <Triangle
          height="80"
          width="80"
          color="#000"
          ariaLabel="triangle-loading"
          visible
        />
      </div>
    );
  }

  if (portfolioItemsError || courseCategoriesError || portfolioPageError) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
          marginBottom: 100,
        }}
      >
        <span>
          {isEnglish
            ? "Something went wrong, please try again!"
            : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
        </span>
      </div>
    );
  }

  const aboutUsData = portfolioPageData?.data ?? {};

  return (
    <>
      <div className="HowTo">
        <Container>
          <div className="HowTo__wrapper">
            <Row>
              <Col md="3" lg="3">
                <div className="HowTo__sidebar">
                  {isMobile ? (
                    <div className="HowTo__sidebar__filter">
                      <label>
                        {isEnglish ? "Categories:" : "Kategoritë:"}{" "}
                      </label>
                      <Select
                        value={active || ""}
                        loading={false}
                        name="order-brands"
                        options={[
                          {
                            value: null as any,
                            label: isEnglish
                              ? "Choose category"
                              : "Zgjedhë kategorinë",
                            isPlaceholder: true,
                          },
                          ...categoriesOptions,
                        ]}
                        onChange={changeCategoryMobile}
                      />
                    </div>
                  ) : (
                    <>
                      <label>{isEnglish ? "Categories" : "Kategoritë"}</label>
                      <div className="HowTo__sidebar__categories">
                        {courseCategories?.map((item: CoursesCategory) => {
                          return (
                            <div
                              key={item.id}
                              onClick={() => changeCategory(item.slug)}
                              className={cs(
                                "HowTo__sidebar__categories_category",
                                active === item.slug &&
                                  "HowTo__sidebar__categories_category_active"
                              )}
                            >
                              <span>{item.title}</span>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </Col>
              <Col md="9" lg="9">
                <div className="HowTo__content">
                  {!isMobile && courseOne ? (
                    <div
                      onClick={() =>
                        history.push(`/portfolio/${courseOne?.slug}`)
                      }
                      className="HowTo__content_big_card"
                    >
                      <div className="HowTo__content_big_card_img">
                        <img alt="" src={courseOne?.thumbnails?.medium_large} />
                      </div>
                      <div className="HowTo__content_big_card_desc">
                        <div className="HowTo__content_big_card_desc">
                          <h1>{courseOne?.title}</h1>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLEntities(
                                courseOne?.short_description
                              ),
                            }}
                          />
                          <div className="HowTo__content_big_card_desc_arrow">
                            <ArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>
                      {isEnglish
                        ? "There is no data for the moment!"
                        : "Nuk ka të dhëna për momentin!"}
                    </p>
                  )}
                  <div className="HowTo__content_cards">
                    {courses?.map(
                      (item: {
                        id: number;
                        content: string;
                        date: string;
                        title: string;
                        slug: string;
                        short_description: string;
                        thumbnail: string;
                        thumbnails: {
                          thumbnail: string;
                          large: string;
                          medium: string;
                          medium_large: string;
                        };
                      }) => {
                        return (
                          <div
                            onClick={() =>
                              history.push(`/portfolio/${item?.slug}`)
                            }
                            key={item.slug}
                            className="HowTo__content_small_card"
                          >
                            <div className="HowTo__content_small_card_img">
                              <img
                                alt="img"
                                src={item.thumbnails?.medium_large}
                              />
                            </div>
                            <div className="HowTo__content_small_card_desc">
                              <div className="HowTo__content_small_card_desc">
                                <div className="HowTo__content_small_card_desc_title">
                                  <h1>{item?.title}</h1>
                                  <div className="HowTo__content_small_card_desc_arrow">
                                    <ArrowRight />
                                  </div>
                                </div>
                                <p>{item?.short_description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {aboutUsData?.more_info?.first_button_text && (
        <div className="Footer__main__top">
          <Container>
            <div className="Footer__main__top__wrapper">
              <h1>{aboutUsData?.more_info?.title}</h1>
              <p>{aboutUsData?.more_info?.description}</p>
              <div className="Footer__main__top__wrapper_btns">
                <div className="Header--bottom--buttons">
                  <button
                    onClick={() =>
                      (window.location.href =
                        aboutUsData?.more_info?.first_button_url)
                    }
                    className="Header--bottom--buttons"
                  >
                    {aboutUsData?.more_info?.first_button_text}
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href =
                        aboutUsData?.more_info?.second_button_url)
                    }
                    className="Header--bottom--buttons-rev"
                  >
                    {aboutUsData?.more_info?.second_button_text}
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};
