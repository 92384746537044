// @ts-nocheck
import { getHomePage } from "api/General";
import { Logo } from "components/Logo/Logo";
import { NavigationBar } from "components/NavigationBar/NavigationBar";
import { useQuery } from "react-query";
import { OtherHeader } from "../OtherHeader/OtherHeader";
import { useRouter } from "lib/hooks/useRouter";
import ReactPlayer from "react-player";

import { Triangle } from "react-loader-spinner";

import "./Header.scss";

export const Header = () => {
  const route = useRouter();
  const { data, isLoading } = useQuery("getHomePage-Header", getHomePage);

  const banner = data?.banner ?? {};

  if (route.location.pathname !== "/") {
    return <OtherHeader />;
  }

  return (
    <div className="Header">
      {isLoading ? (
        <Triangle
          height="80"
          width="80"
          color="#000"
          ariaLabel="triangle-loading"
          visible
        />
      ) : (
        <>
          <div className="Header__backgroundImg">
            {banner?.image_or_video === "video" ? (
              <ReactPlayer
                width="100%"
                height="100%"
                stopOnUnmount
                loop
                playing
                url={banner.video}
                volume={0}
                muted
                playsinline
              />
            ) : (
              <img alt="background_img" src={banner?.image} />
            )}
          </div>
          <div className="Header--top">
            <Logo />
            <NavigationBar />
          </div>
          <div className="Header--bottom">
            <h1>{banner?.title}</h1>
            <p>{banner?.description}</p>
            <div className="Header--bottom--buttons">
              <button
                onClick={() =>
                  (window.location.href = banner?.first_button_url)
                }
                className="Header--bottom--buttons"
              >
                {banner?.first_button_text}
              </button>
              <button
                onClick={() =>
                  (window.location.href = banner?.second_button_url)
                }
                className="Header--bottom--buttons-rev"
              >
                {banner?.second_button_text}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
