import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import Flickity from "react-flickity-component";
import { Container } from "../Container/Container";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import "flickity/dist/flickity.css";
import "./SlideShow.scss";

interface SlideShowProps {
  items: any;
  title: string;
  loading: boolean;
  paddingLeft?: number;
  redirectLink?: string;
  description?: string;
}

export const SlideShow = (props: SlideShowProps) => {
  const history = useHistory();
  const flickity = useRef<Flickity | null>(null);
  const [titleRef, setTitleRef] = useState<any>(null);
  const windowWidth = useWindowWidth();

  const isTablet = windowWidth < 1024;

  const getTitleRef = useCallback((c) => {
    if (c !== null) {
      setTitleRef(c);
    }
  }, []);

  const mobileItems = props.items;

  const handleDragStart = () => {
    const elementCurrent = flickity.current as any;
    // Add the CSS class to disable pointer events on items during drag
    elementCurrent?.element?.classList.add("disable-pointer-events");
  };

  const handleDragEnd = () => {
    const elementCurrent = flickity.current as any;

    // Remove the CSS class to enable pointer events on items after drag
    elementCurrent?.element?.classList.remove("disable-pointer-events");
  };

  useEffect(() => {
    // Add custom event listeners for drag start and end
    const flickityInstance = flickity.current;
    if (flickityInstance) {
      flickityInstance.on("dragStart", handleDragStart);
      flickityInstance.on("dragEnd", handleDragEnd);
    }

    return () => {
      // Remove event listeners when the component unmounts
      if (flickityInstance) {
        flickityInstance.off("dragStart", handleDragStart);
        flickityInstance.off("dragEnd", handleDragEnd);
      }
    };
  }, []);

  return (
    <div className="SlideShow">
      <Container className="p-0-mobile d-flex justify-content-between SlideShow-head">
        <h2 ref={getTitleRef} className="font-weight-bold SlideShow__title_t">
          {props.title}
        </h2>
        <div className="SlideShow__title d-flex">
          <span>{props.description}</span>
        </div>
      </Container>
      <div className="SlideShow__wrapper">
        {!isTablet ? (
          <React.Fragment>
            {/*  @ts-ignore */}
            <Flickity
              flickityRef={(c) => (flickity.current = c)}
              className={"carousel"}
              elementType={"div"}
              options={{
                wrapAround: false,
                draggable: true,
                cellAlign: props.items?.length <= 4 ? "center" : "left",
                freeScroll: true,
                resize: true,
                pageDots: false,
                prevNextButtons: false,
                contain: true,
                bgLazyLoad: true,
                lazyLoad: 1,
                dragThreshold: 1,
              }}
              disableImagesLoaded={false}
            >
              {!isTablet && (
                <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
              )}
              {props.items?.map(
                (
                  product: {
                    slug: string;
                    title: string;
                    content: string;
                    image: string;
                    background_color: string;
                    short_description: string;
                  },
                  index: number
                ) => {
                  return (
                    <div
                      onClick={() =>
                        history.push(`/kategoritë/${product.slug}`)
                      }
                      key={`${product?.slug}-${index}`}
                      className="SlideShow-box"
                      id={`SlideShow-${product?.slug}`}
                    >
                      <div className="SlideShow--category">
                        <div className="SlideShow--category-img">
                          <img alt="img" src={product?.image} />
                        </div>

                        <div
                          style={{ backgroundColor: product.background_color }}
                          className="SlideShow--category-desc"
                        >
                          <span>{product?.title}</span>
                          <p>{product?.short_description}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
              {!isTablet && (
                <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
              )}
            </Flickity>
          </React.Fragment>
        ) : (
          <div className="HomePageSlideShow--mobile">
            {mobileItems?.map(
              (
                item: {
                  slug: string;
                  title: string;
                  content: string;
                  image: string;
                  background_color: string;
                  short_description: string;
                },
                index: number
              ) => {
                return (
                  <div
                    onClick={() => history.push(`/kategoritë/${item.slug}`)}
                    key={index}
                    className="HomeSlider__slide d-flex flex-column"
                  >
                    <div
                      key={`${item?.slug}-${index}`}
                      className="HomeSlider__item"
                      id={`SlideShow-${item?.slug}`}
                    >
                      <div className="SlideShow--category">
                        <div className="SlideShow--category-img">
                          <img src={item?.image} alt="logo" />
                        </div>
                        <div
                          style={{ backgroundColor: item.background_color }}
                          className="SlideShow--category-desc"
                        >
                          <span>{item?.title}</span>
                          <p>{item?.short_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    </div>
  );
};
