import { SubscribeField, subscribeUser } from "api/General";
import { useMutation, UseMutationOptions } from "react-query";
import { toast } from "react-toastify";

export const useSubscribe = (
  options?: UseMutationOptions<any, any, SubscribeField, unknown>
) => {
  return useMutation(subscribeUser, {
    onSuccess: (res) => {
      toast.success(res.response?.data?.error);
    },
    onError: (e) => {
      toast.error(e.response?.data?.error);
    },
    ...options,
  });
};
