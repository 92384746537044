import { getPortfolioBySlug } from "api/General";
import React from "react";
import { useTranslation } from "react-i18next";
import { Triangle } from "react-loader-spinner";
import { useQuery } from "react-query";

import { Container } from "../../components/Container/Container";

import "./HowToSingle.scss";

export const HowToSingle = (props: any) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const portfoliSlug = props.match.params.slug ?? "";

  const { data, isLoading, isError } = useQuery(
    ["getPortfolioBySlug", portfoliSlug],
    () => getPortfolioBySlug(portfoliSlug)
  );

  const course = data ?? {};

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
          marginBottom: 100,
        }}
      >
        <Triangle
          height="80"
          width="80"
          color="#000"
          ariaLabel="triangle-loading"
          visible
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
          marginBottom: 100,
        }}
      >
        <span>
          {isEnglish
            ? "Something went wrong, please try again!"
            : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
        </span>
      </div>
    );
  }

  return (
    <div className="HowToSingle">
      <Container>
        <div className="HowToSingle__wrapper">
          <div className="HowToSingle__wrapper_video">
            <img alt="img" src={course?.thumbnails?.medium_large} />
          </div>
          <div className="HowToSingle__wrapper__content">
            <h1>{course?.title}</h1>
            <div className="HowToSingle__wrapper__content_card">
              <h3>{isEnglish ? "Description" : "Pëshkrimi"}</h3>
              <div dangerouslySetInnerHTML={{ __html: course?.content }} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
