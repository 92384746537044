import React from "react";
import { useHistory } from "react-router";
import { Button } from "components/shared/Button/Button";

//styles
import "./Page404.scss";

export const Page404 = () => {
  const history = useHistory();

  return (
    <div className="Page404">
      <h1>404</h1>
      <span>Page not found</span>
      <p>
        Oops! The page you are looking for does not exist. It might have been
        moved or deleted.
      </p>
      <Button role="link" onClick={() => history.push("/")}>
        Back to home
      </Button>
    </div>
  );
};
